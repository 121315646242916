
<template>
  <div id="page-user-rdit">
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-lg-4 order-lg-2 col-xl-4">
            <div class="card">
                <div class="card-body text-center">
                    <img :src="absoluteUrl(activeUser.image)" class="rounded-circle avatar-xl img-thumbnail" alt="profile-image" />
                    <h4 class="mt-3 mb-0">{{activeUser.first_name}} {{activeUser.last_name}}</h4>
                    <p class="text-muted">Type: {{activeUser.role}}</p>
                    <button type="button" class="btn mr-1 btn-success btn-xs waves-effect mb-2 waves-light">
                      {{activeUser.status}}
                    </button>
                    <span>
                    <button type="button" class="btn btn-xs waves-effect mb-2 waves-light" :class="activeUser.is_verified ? 'btn-info' : 'btn-danger'">
                      {{activeUser.is_verified ? 'Verified' : 'Not Verified'}}
                    </button>
                    </span>
                </div>
            </div>
        </div>
        <!-- end col-->
        <div class="col-lg-8 col-xl-8">
            <div class="card">
                <div class="card-body">
                    <b-tabs content-class="mt-1" pills class="navtab-bg">
                        <b-tab :active="activeTab === 'profile'">
                            <template v-slot:title>
                                <i class="mdi mdi-account-check mr-1"></i> Profile
                            </template>
                            <user-edit-tab-account />
                        </b-tab>
                        <b-tab :active="activeTab === 'settings'">
                        <template v-slot:title>
                            <i class="mdi mdi-account-cog mr-1"></i> Settings
                        </template>
                            <user-edit-tab-information />
                        </b-tab>
                        <b-tab :active="activeTab === 'password'">
                        <template v-slot:title>
                            <i class="mdi mdi-lock-check mr-1"></i> Password
                        </template>
                            <user-edit-tab-password />
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
        <!-- end col -->
    </div>
    </div>
    <is-loading v-else />
  </div>
</template>

<script>
import IsLoading from "@/components/IsLoading.vue"
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    activeTab(){
      return this.$route.query.tab ? this.$route.query.tab : 'profile';
    },
  },
  created() {
    this.$store.dispatch("fetchActiveUser")
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: "/error-404"})
        }
    })
  }
}

</script>
